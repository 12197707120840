import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { ModalUnstyled } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
import { useConnectionSecrets } from '@the-platform-company/appbuilder-react-connector'
const ModalsView = React.lazy(
  () => import(/* webpackChunkName: "views/modals" */ './views/Modals')
)
const MenusView = React.lazy(
  () => import(/* webpackChunkName: "views/menus" */ './views/Menus')
)
const MasterView = React.lazy(
  () => import(/* webpackChunkName: "views/master" */ './views/Master')
)
const NotFoundView = React.lazy(
  () => import(/* webpackChunkName: "views/not-found" */ './views/NotFound')
)
const ModalsLanguageAndRegionView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/language-and-region" */ './views/ModalsLanguageAndRegion'
    )
)
const ModalsRemoveUsersFromGroupView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/remove-users-from-group" */ './views/ModalsRemoveUsersFromGroup'
    )
)
const ModalsCreateApiKeyView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-api-key" */ './views/ModalsCreateApiKey'
    )
)
const ModalsEditGroupView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/edit-group" */ './views/ModalsEditGroup')
)
const ModalsApiKeyCreatedView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/api-key-created" */ './views/ModalsApiKeyCreated'
    )
)
const ModalsProfileView = React.lazy(
  () => import(/* webpackChunkName: "views/profile" */ './views/ModalsProfile')
)
const ModalsAddUserView = React.lazy(
  () => import(/* webpackChunkName: "views/add-user" */ './views/ModalsAddUser')
)
const ModalsCreateGroupView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-group" */ './views/ModalsCreateGroup'
    )
)
const ModalsAddUsersToGroupView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-users-to-group" */ './views/ModalsAddUsersToGroup'
    )
)
const ModalsInviteUsersView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/invite-users" */ './views/ModalsInviteUsers'
    )
)
const ModalsEditUserProfileView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/edit-user-profile" */ './views/ModalsEditUserProfile'
    )
)
const ModalsConfirmDeleteGroupView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-group" */ './views/ModalsConfirmDeleteGroup'
    )
)
const ModalsConfirmDeleteUserView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-user" */ './views/ModalsConfirmDeleteUser'
    )
)
const ModalsUserLanguageAndRegionView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/user-language-and-region" */ './views/ModalsUserLanguageAndRegion'
    )
)
const ModalsConfirmRemoveUserFormGroupView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-remove-user-form-group" */ './views/ModalsConfirmRemoveUserFormGroup'
    )
)
const ModalsEditUserAvatarView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/edit-user-avatar" */ './views/ModalsEditUserAvatar'
    )
)
const ModalsEditAccountAvatarView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/edit-account-avatar" */ './views/ModalsEditAccountAvatar'
    )
)
const ModalsCreateInvitationLinkView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/create-invitation-link" */ './views/ModalsCreateInvitationLink'
    )
)
const ModalsChangePasswordView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/change-password" */ './views/ModalsChangePassword'
    )
)
const ModalsConfirmDeleteIdentityAssignmentView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-identity-assignment" */ './views/ModalsConfirmDeleteIdentityAssignment'
    )
)
const ModalsAddSocialLoginView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-social-login" */ './views/ModalsAddSocialLogin'
    )
)
const ModalsAddEmailAndPasswordView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-email-and-password" */ './views/ModalsAddEmailAndPassword'
    )
)
const ModalsConfirmDeleteLoginView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/confirm-delete-login" */ './views/ModalsConfirmDeleteLogin'
    )
)
const ModalsAddUserToGroupView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-user-to-group" */ './views/ModalsAddUserToGroup'
    )
)
const ModalsAddChildAccountView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/add-child-account" */ './views/ModalsAddChildAccount'
    )
)
const MenusGroupsMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/groups-menu" */ './views/MenusGroupsMenu'
    )
)
const MenusUsersMenuView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/users-menu" */ './views/MenusUsersMenu')
)
const MenusApiKeysMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/api-keys-menu" */ './views/MenusApiKeysMenu'
    )
)
const MenusAccountsMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/accounts-menu" */ './views/MenusAccountsMenu'
    )
)
const MenusUserMenuView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/user-menu" */ './views/MenusUserMenu')
)
const MenusApplicationMenuView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/application-menu" */ './views/MenusApplicationMenu'
    )
)
const MasterUsersView = React.lazy(
  () => import(/* webpackChunkName: "views/users" */ './views/MasterUsers')
)
const MasterGroupsView = React.lazy(
  () => import(/* webpackChunkName: "views/groups" */ './views/MasterGroups')
)
const MasterUserDetailsView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/user-details" */ './views/MasterUserDetails'
    )
)
const MasterApiKeysView = React.lazy(
  () => import(/* webpackChunkName: "views/api-keys" */ './views/MasterApiKeys')
)
const MasterAccountsView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/accounts" */ './views/MasterAccounts')
)
const MasterCallbackView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/callback" */ './views/MasterCallback')
)
const MasterAccountProfileView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/account-profile" */ './views/MasterAccountProfile'
    )
)
const MasterStartView = React.lazy(
  () => import(/* webpackChunkName: "views/start" */ './views/MasterStart')
)
const MasterLoginView = React.lazy(
  () => import(/* webpackChunkName: "views/login" */ './views/MasterLogin')
)
const MasterGroupsMembersView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/members" */ './views/MasterGroupsMembers'
    )
)
const Tosopr004AuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/Tosopr004AuthCallback" */ './components/connectors/Tosopr004AuthCallback'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const connections = useConnectionSecrets({ connectionNames: ['tosopr004'] })
  const assets = useMemo(
    () => ({
      robotenIconPng: '/assets/roboten-icon-png/roboten-icon.png',
      mobilePhonesPng: '/assets/mobile-phones-png/mobile-phones.png',
      closeAccountPng: '/assets/close-account-png/close-account.png',
      emailDeletePng: '/assets/email-delete-png/email-delete.png',
      linkedinSvg: '/assets/linkedin-svg/Linkedin.svg',
      githubSvg: '/assets/github-svg/Github.svg',
      twitterSvg: '/assets/twitter-svg/Twitter.svg',
      googleSvg: '/assets/google-svg/Google.svg',
      adminCloseAccountSvg:
        '/assets/admin-close-account-svg/Admin_Close account.svg',
      faviconPng: '/assets/favicon-png/favicon.png',
      myAccountAddEmailSvg:
        '/assets/my-account-add-email-svg/My account_Add email.svg',
      closeAccountCloseAccountSvg:
        '/assets/close-account-close-account-svg/close_account-close_account.svg',
      adminCreateGroupDialogSvg:
        '/assets/admin-create-group-dialog-svg/Admin_Create group dialog.svg',
      adminInviteUserSvg: '/assets/admin-invite-user-svg/Admin_Invite user.svg',
      myAccountDeletePhoneNumberSvg:
        '/assets/my-account-delete-phone-number-svg/My account_Delete phone number.svg',
      myAccountDeleteEmailSvg:
        '/assets/my-account-delete-email-svg/My account_Delete Email.svg',
      myAccountCreateEmailAndPasswordLoginDialogSvg:
        '/assets/my-account-create-email-and-password-login-dialog-svg/My account_Create email and password login dialog.svg',
      myAccountChangePasswordSvg:
        '/assets/my-account-change-password-svg/My account_Change password.svg',
      myAccountEditProfileSvg:
        '/assets/my-account-edit-profile-svg/My account_Edit profile.svg',
      myAccountEmailAndPasswordSvg:
        '/assets/my-account-email-and-password-svg/My account_Email and password.svg',
      adminEditGroupDialogSvg:
        '/assets/admin-edit-group-dialog-svg/Admin_Edit group dialog.svg',
      myAccountLanguageAndRegionSvg:
        '/assets/my-account-language-and-region-svg/My account_Language and region.svg',
      appIcon_04Svg: '/assets/app-icon-04-svg/app_icon_04.svg',
      appIcon_08Svg: '/assets/app-icon-08-svg/app_icon_08.svg',
      appIcon_02Svg: '/assets/app-icon-02-svg/app_icon_02.svg',
      appIcon_01Svg: '/assets/app-icon-01-svg/app_icon_01.svg',
      appIcon_06Svg: '/assets/app-icon-06-svg/app_icon_06.svg',
      appIcon_03Svg: '/assets/app-icon-03-svg/app_icon_03.svg',
      appIcon_07Svg: '/assets/app-icon-07-svg/app_icon_07.svg',
      appIcon_05Svg: '/assets/app-icon-05-svg/app_icon_05.svg',
      appIcon_09Svg: '/assets/app-icon-09-svg/app_icon_09.svg',
      notFoundSvg: '/assets/not-found-svg/not-found.svg',
      noRecordsSvg: '/assets/no-records-svg/no-records.svg',
    }),
    []
  )
  return (
    <>
      <StateProvider
        additionalState={{
          assets,
          connections,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'f48652e8-ea2f-450e-8ace-21c3c660fd3e'}
                      >
                        <MasterView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/"
                      element={
                        <ViewWrapper
                          namespace={'055f418d-05d5-4970-a552-c6b765ad6b3e'}
                        >
                          <MasterStartView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/users/:id"
                      element={
                        <ViewWrapper
                          namespace={'b371e79b-0209-41c1-a41e-50b8ef7dbeac'}
                        >
                          <MasterUsersView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/groups/:id"
                      element={
                        <ViewWrapper
                          namespace={'1922e31d-2eb4-47a7-abc6-c2c1196f33c0'}
                        >
                          <MasterGroupsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/groups/:id/members/:groupId"
                      element={
                        <ViewWrapper
                          namespace={'2a08066a-35f3-4fc5-a55e-7b2361e7293c'}
                        >
                          <MasterGroupsMembersView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/user-details/:userId"
                      element={
                        <ViewWrapper
                          namespace={'f6c8f39b-e772-4286-97e0-888a077059b7'}
                        >
                          <MasterUserDetailsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/api-keys"
                      element={
                        <ViewWrapper
                          namespace={'eafbfa66-ee69-4107-a04c-f99e9dd2e07b'}
                        >
                          <MasterApiKeysView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/accounts"
                      element={
                        <ViewWrapper
                          namespace={'47f54231-ca23-41ac-b89d-c877e0963d3d'}
                        >
                          <MasterAccountsView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/callback"
                      element={
                        <ViewWrapper
                          namespace={'59fd89bf-c8d4-4f9b-b770-2bda5e3923eb'}
                        >
                          <MasterCallbackView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/account-profile/:id"
                      element={
                        <ViewWrapper
                          namespace={'d35a349b-97e9-4167-a40b-1b629373cef3'}
                        >
                          <MasterAccountProfileView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <ViewWrapper
                          namespace={'90b9db03-758d-4311-8eb6-60b954545c6c'}
                        >
                          <MasterLoginView />
                        </ViewWrapper>
                      }
                    />
                  </Route>
                  <Route
                    path="/*"
                    element={
                      <ViewWrapper
                        namespace={'a5aa0ac9-ebe0-4887-bb2d-2017898a080d'}
                      >
                        <NotFoundView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'21a3704c-37f5-4bfd-aad6-a9630aca3c31'}
                      >
                        <ModalsView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/language-and-region/:id"
                      element={
                        <ViewWrapper
                          namespace={'5cac8aec-203d-48f7-a10e-75e53dde6aa8'}
                        >
                          <ModalsLanguageAndRegionView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/remove-users-from-group"
                      element={
                        <ViewWrapper
                          namespace={'6e991ce7-e009-4c12-ae38-ba7388b5f780'}
                        >
                          <ModalsRemoveUsersFromGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/create-api-key"
                      element={
                        <ViewWrapper
                          namespace={'8b966523-cd71-4683-a118-7f1bd132c4b8'}
                        >
                          <ModalsCreateApiKeyView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/edit-group/:id"
                      element={
                        <ViewWrapper
                          namespace={'4f99cab7-c7f3-4cf5-9e66-51ad6aea4c04'}
                        >
                          <ModalsEditGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/api-key-created"
                      element={
                        <ViewWrapper
                          namespace={'38a25a6e-f7c4-4b16-b61d-90a3963b7c5f'}
                        >
                          <ModalsApiKeyCreatedView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/profile/:id"
                      element={
                        <ViewWrapper
                          namespace={'2e032527-f38e-41c5-a0f0-7110d710254c'}
                        >
                          <ModalsProfileView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-user/:id"
                      element={
                        <ViewWrapper
                          namespace={'b16b91ab-b133-4d86-81ef-8d817d08338a'}
                        >
                          <ModalsAddUserView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/create-group/:id"
                      element={
                        <ViewWrapper
                          namespace={'5f0b90a6-0de3-493d-b76a-c40f3d71bec9'}
                        >
                          <ModalsCreateGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-users-to-group/:accountId/:groupId"
                      element={
                        <ViewWrapper
                          namespace={'edd687c1-d126-4f5c-877d-eeb76c775675'}
                        >
                          <ModalsAddUsersToGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/invite-users/:id"
                      element={
                        <ViewWrapper
                          namespace={'f6894f76-62c9-47f8-934f-ffb601529ec7'}
                        >
                          <ModalsInviteUsersView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/edit-user-profile/:id"
                      element={
                        <ViewWrapper
                          namespace={'f611af11-5988-4f29-a46b-7ca17355916b'}
                        >
                          <ModalsEditUserProfileView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-group/:id"
                      element={
                        <ViewWrapper
                          namespace={'2fa3305e-35ea-4605-8632-256b1be9b193'}
                        >
                          <ModalsConfirmDeleteGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-user/:id"
                      element={
                        <ViewWrapper
                          namespace={'7015e8cc-9935-42dc-a9ab-afe52c44096f'}
                        >
                          <ModalsConfirmDeleteUserView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/user-language-and-region/:id"
                      element={
                        <ViewWrapper
                          namespace={'7ed59ee0-7622-4b84-81a0-3e143cfc3412'}
                        >
                          <ModalsUserLanguageAndRegionView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-remove-user-form-group/:groupId/:userId"
                      element={
                        <ViewWrapper
                          namespace={'a25ca1e4-1c6a-41b8-99d2-dc9d0fc802d2'}
                        >
                          <ModalsConfirmRemoveUserFormGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/edit-user-avatar"
                      element={
                        <ViewWrapper
                          namespace={'f0b44462-c822-45ad-b217-708eaaa11dc4'}
                        >
                          <ModalsEditUserAvatarView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/edit-account-avatar"
                      element={
                        <ViewWrapper
                          namespace={'7289e398-20e0-4395-9e18-71abe634de13'}
                        >
                          <ModalsEditAccountAvatarView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/create-invitation-link"
                      element={
                        <ViewWrapper
                          namespace={'9d4891bf-b6d2-4cbb-95f9-9fa128552ae2'}
                        >
                          <ModalsCreateInvitationLinkView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/change-password/:id"
                      element={
                        <ViewWrapper
                          namespace={'71d46613-ac01-4b71-abef-d3e140b825e6'}
                        >
                          <ModalsChangePasswordView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-identity-assignment/:id"
                      element={
                        <ViewWrapper
                          namespace={'141cc286-bbd9-4456-a12c-06f2b614488a'}
                        >
                          <ModalsConfirmDeleteIdentityAssignmentView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-social-login/:id"
                      element={
                        <ViewWrapper
                          namespace={'34783199-c748-4c5c-90fc-3bac3c269650'}
                        >
                          <ModalsAddSocialLoginView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-email-and-password/:id"
                      element={
                        <ViewWrapper
                          namespace={'2bb6caca-d9a5-4a63-931a-1dc13f343377'}
                        >
                          <ModalsAddEmailAndPasswordView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/confirm-delete-login/:id"
                      element={
                        <ViewWrapper
                          namespace={'dc688cbd-29a6-400f-a9c9-c4442fe073e3'}
                        >
                          <ModalsConfirmDeleteLoginView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-user-to-group/:accountId/:userId"
                      element={
                        <ViewWrapper
                          namespace={'737e003a-e3a0-40d3-9260-a2e4a5441e4d'}
                        >
                          <ModalsAddUserToGroupView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/add-child-account/:accountId"
                      element={
                        <ViewWrapper
                          namespace={'814b024c-c47d-44ee-ae42-c30914d8c773'}
                        >
                          <ModalsAddChildAccountView />
                        </ViewWrapper>
                      }
                    />
                  </Route>
                  <Route
                    path=""
                    element={
                      <ViewWrapper
                        namespace={'f2210f2b-cbfd-495a-a313-b7c7dec0add2'}
                      >
                        <MenusView />
                      </ViewWrapper>
                    }
                  >
                    <Route
                      path="/groups-menu/:accountId/:groupId"
                      element={
                        <ViewWrapper
                          namespace={'10b7c6a4-c472-4468-a7bc-d0954409654f'}
                        >
                          <MenusGroupsMenuView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/users-menu/:id"
                      element={
                        <ViewWrapper
                          namespace={'9a63d3a3-fe59-4634-898c-3f2da8bfb179'}
                        >
                          <MenusUsersMenuView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/api-keys-menu"
                      element={
                        <ViewWrapper
                          namespace={'34133098-f26d-48d9-9aed-765bde098ca2'}
                        >
                          <MenusApiKeysMenuView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/accounts-menu/:id"
                      element={
                        <ViewWrapper
                          namespace={'d8b33bb5-52de-4b22-9739-04211547c9a2'}
                        >
                          <MenusAccountsMenuView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/user-menu"
                      element={
                        <ViewWrapper
                          namespace={'9acc3bb6-152c-41fb-b2b6-00380495f040'}
                        >
                          <MenusUserMenuView />
                        </ViewWrapper>
                      }
                    />
                    <Route
                      path="/application-menu"
                      element={
                        <ViewWrapper
                          namespace={'456adf6d-6525-4603-9562-0e394ff5fd4b'}
                        >
                          <MenusApplicationMenuView />
                        </ViewWrapper>
                      }
                    />
                  </Route>

                  <Route
                    path="/auth/tosopr004/callback"
                    element={<Tosopr004AuthCallback />}
                  />
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <ModalUnstyled
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </ModalUnstyled>
                      }
                    >
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'f48652e8-ea2f-450e-8ace-21c3c660fd3e'}
                          >
                            <MasterView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/"
                          element={
                            <ViewWrapper
                              namespace={'055f418d-05d5-4970-a552-c6b765ad6b3e'}
                            >
                              <MasterStartView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/users/:id"
                          element={
                            <ViewWrapper
                              namespace={'b371e79b-0209-41c1-a41e-50b8ef7dbeac'}
                            >
                              <MasterUsersView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/groups/:id"
                          element={
                            <ViewWrapper
                              namespace={'1922e31d-2eb4-47a7-abc6-c2c1196f33c0'}
                            >
                              <MasterGroupsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/groups/:id/members/:groupId"
                          element={
                            <ViewWrapper
                              namespace={'2a08066a-35f3-4fc5-a55e-7b2361e7293c'}
                            >
                              <MasterGroupsMembersView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/user-details/:userId"
                          element={
                            <ViewWrapper
                              namespace={'f6c8f39b-e772-4286-97e0-888a077059b7'}
                            >
                              <MasterUserDetailsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/api-keys"
                          element={
                            <ViewWrapper
                              namespace={'eafbfa66-ee69-4107-a04c-f99e9dd2e07b'}
                            >
                              <MasterApiKeysView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/accounts"
                          element={
                            <ViewWrapper
                              namespace={'47f54231-ca23-41ac-b89d-c877e0963d3d'}
                            >
                              <MasterAccountsView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/callback"
                          element={
                            <ViewWrapper
                              namespace={'59fd89bf-c8d4-4f9b-b770-2bda5e3923eb'}
                            >
                              <MasterCallbackView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/account-profile/:id"
                          element={
                            <ViewWrapper
                              namespace={'d35a349b-97e9-4167-a40b-1b629373cef3'}
                            >
                              <MasterAccountProfileView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/login"
                          element={
                            <ViewWrapper
                              namespace={'90b9db03-758d-4311-8eb6-60b954545c6c'}
                            >
                              <MasterLoginView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                      <Route
                        path="/*"
                        element={
                          <ViewWrapper
                            namespace={'a5aa0ac9-ebe0-4887-bb2d-2017898a080d'}
                          >
                            <NotFoundView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'21a3704c-37f5-4bfd-aad6-a9630aca3c31'}
                          >
                            <ModalsView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/language-and-region/:id"
                          element={
                            <ViewWrapper
                              namespace={'5cac8aec-203d-48f7-a10e-75e53dde6aa8'}
                            >
                              <ModalsLanguageAndRegionView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/remove-users-from-group"
                          element={
                            <ViewWrapper
                              namespace={'6e991ce7-e009-4c12-ae38-ba7388b5f780'}
                            >
                              <ModalsRemoveUsersFromGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/create-api-key"
                          element={
                            <ViewWrapper
                              namespace={'8b966523-cd71-4683-a118-7f1bd132c4b8'}
                            >
                              <ModalsCreateApiKeyView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/edit-group/:id"
                          element={
                            <ViewWrapper
                              namespace={'4f99cab7-c7f3-4cf5-9e66-51ad6aea4c04'}
                            >
                              <ModalsEditGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/api-key-created"
                          element={
                            <ViewWrapper
                              namespace={'38a25a6e-f7c4-4b16-b61d-90a3963b7c5f'}
                            >
                              <ModalsApiKeyCreatedView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/profile/:id"
                          element={
                            <ViewWrapper
                              namespace={'2e032527-f38e-41c5-a0f0-7110d710254c'}
                            >
                              <ModalsProfileView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-user/:id"
                          element={
                            <ViewWrapper
                              namespace={'b16b91ab-b133-4d86-81ef-8d817d08338a'}
                            >
                              <ModalsAddUserView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/create-group/:id"
                          element={
                            <ViewWrapper
                              namespace={'5f0b90a6-0de3-493d-b76a-c40f3d71bec9'}
                            >
                              <ModalsCreateGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-users-to-group/:accountId/:groupId"
                          element={
                            <ViewWrapper
                              namespace={'edd687c1-d126-4f5c-877d-eeb76c775675'}
                            >
                              <ModalsAddUsersToGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/invite-users/:id"
                          element={
                            <ViewWrapper
                              namespace={'f6894f76-62c9-47f8-934f-ffb601529ec7'}
                            >
                              <ModalsInviteUsersView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/edit-user-profile/:id"
                          element={
                            <ViewWrapper
                              namespace={'f611af11-5988-4f29-a46b-7ca17355916b'}
                            >
                              <ModalsEditUserProfileView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-group/:id"
                          element={
                            <ViewWrapper
                              namespace={'2fa3305e-35ea-4605-8632-256b1be9b193'}
                            >
                              <ModalsConfirmDeleteGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-user/:id"
                          element={
                            <ViewWrapper
                              namespace={'7015e8cc-9935-42dc-a9ab-afe52c44096f'}
                            >
                              <ModalsConfirmDeleteUserView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/user-language-and-region/:id"
                          element={
                            <ViewWrapper
                              namespace={'7ed59ee0-7622-4b84-81a0-3e143cfc3412'}
                            >
                              <ModalsUserLanguageAndRegionView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-remove-user-form-group/:groupId/:userId"
                          element={
                            <ViewWrapper
                              namespace={'a25ca1e4-1c6a-41b8-99d2-dc9d0fc802d2'}
                            >
                              <ModalsConfirmRemoveUserFormGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/edit-user-avatar"
                          element={
                            <ViewWrapper
                              namespace={'f0b44462-c822-45ad-b217-708eaaa11dc4'}
                            >
                              <ModalsEditUserAvatarView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/edit-account-avatar"
                          element={
                            <ViewWrapper
                              namespace={'7289e398-20e0-4395-9e18-71abe634de13'}
                            >
                              <ModalsEditAccountAvatarView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/create-invitation-link"
                          element={
                            <ViewWrapper
                              namespace={'9d4891bf-b6d2-4cbb-95f9-9fa128552ae2'}
                            >
                              <ModalsCreateInvitationLinkView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/change-password/:id"
                          element={
                            <ViewWrapper
                              namespace={'71d46613-ac01-4b71-abef-d3e140b825e6'}
                            >
                              <ModalsChangePasswordView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-identity-assignment/:id"
                          element={
                            <ViewWrapper
                              namespace={'141cc286-bbd9-4456-a12c-06f2b614488a'}
                            >
                              <ModalsConfirmDeleteIdentityAssignmentView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-social-login/:id"
                          element={
                            <ViewWrapper
                              namespace={'34783199-c748-4c5c-90fc-3bac3c269650'}
                            >
                              <ModalsAddSocialLoginView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-email-and-password/:id"
                          element={
                            <ViewWrapper
                              namespace={'2bb6caca-d9a5-4a63-931a-1dc13f343377'}
                            >
                              <ModalsAddEmailAndPasswordView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/confirm-delete-login/:id"
                          element={
                            <ViewWrapper
                              namespace={'dc688cbd-29a6-400f-a9c9-c4442fe073e3'}
                            >
                              <ModalsConfirmDeleteLoginView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-user-to-group/:accountId/:userId"
                          element={
                            <ViewWrapper
                              namespace={'737e003a-e3a0-40d3-9260-a2e4a5441e4d'}
                            >
                              <ModalsAddUserToGroupView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/add-child-account/:accountId"
                          element={
                            <ViewWrapper
                              namespace={'814b024c-c47d-44ee-ae42-c30914d8c773'}
                            >
                              <ModalsAddChildAccountView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                      <Route
                        path=""
                        element={
                          <ViewWrapper
                            namespace={'f2210f2b-cbfd-495a-a313-b7c7dec0add2'}
                          >
                            <MenusView />
                          </ViewWrapper>
                        }
                      >
                        <Route
                          path="/groups-menu/:accountId/:groupId"
                          element={
                            <ViewWrapper
                              namespace={'10b7c6a4-c472-4468-a7bc-d0954409654f'}
                            >
                              <MenusGroupsMenuView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/users-menu/:id"
                          element={
                            <ViewWrapper
                              namespace={'9a63d3a3-fe59-4634-898c-3f2da8bfb179'}
                            >
                              <MenusUsersMenuView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/api-keys-menu"
                          element={
                            <ViewWrapper
                              namespace={'34133098-f26d-48d9-9aed-765bde098ca2'}
                            >
                              <MenusApiKeysMenuView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/accounts-menu/:id"
                          element={
                            <ViewWrapper
                              namespace={'d8b33bb5-52de-4b22-9739-04211547c9a2'}
                            >
                              <MenusAccountsMenuView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/user-menu"
                          element={
                            <ViewWrapper
                              namespace={'9acc3bb6-152c-41fb-b2b6-00380495f040'}
                            >
                              <MenusUserMenuView />
                            </ViewWrapper>
                          }
                        />
                        <Route
                          path="/application-menu"
                          element={
                            <ViewWrapper
                              namespace={'456adf6d-6525-4603-9562-0e394ff5fd4b'}
                            >
                              <MenusApplicationMenuView />
                            </ViewWrapper>
                          }
                        />
                      </Route>
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
